@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -ms-overflow-style: none; */
}

body {
  background-color: #f7f8f9 !important;
  margin: 0;
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat' !important;
}

header {
  background-color: #282c34;
  color: white;
  font-family: 'Montserrat' !important;
}

/* .Example__container__document {
  margin: 3em 0;
} */
/* .react-pdf__Page__canvas{
  margin: 1em 0;
} */
.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); */
  margin: 3em;
  /* padding-left: 20px; */
}

.Example__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.Example__container__document .react-pdf__message {
  /* padding: 20px; */
  color: white;
  display: none;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}
.react-pdf__message {
  display: none;
}
.react-pdf__message--no-data{
  display: none;
}
/* .react-pdf__message .react-pdf__message--loading{
  display: none;
} */
/* .react-pdf__Page{

} */

/* .d-flex justify-content-between align-items-baseline {
  padding-top: 20px;
  text-align: center;
  padding-left: 550px;
} */
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
  opacity:0;
}

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
  opacity:0;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
  opacity:0;
}
video::-webkit-media-controls {
  display:none !important;
  opacity:0;
}
@media only screen and (max-device-width : 375px){
  .Example__container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    margin: 3em;
    padding-left: 35px;
  }

}
